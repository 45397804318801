<template>
  <v-container fluid>
    <v-row>
      <v-col class="bg-export" cols="12" sm="12">
        <exportForm
          :horaires="horaires"
          :secteurs="secteurs"
          :marches="marches"
          :types="types"
          :fields="fields"
          :btn="btn"
          @getSecteurs="getSecteurs($event)"
          @getPlageHoraire="getPlageHoraire($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import exportForm from "@/components/admin/export/exportForm";
import apiMarches from "@/api/marches";
import apiSecteurs from "@/api/secteurs";
import apiCollectes from "@/api/collectes";

export default {
  components: {
    exportForm,
  },

  data() {
    return {
      fields: [
        {
          value: "",
          name: "Marché",
          placeholder: "Marché *",
          rules: "required",
          type: "marches",
        },
        {
          value: [],
          name: "Secteur",
          placeholder: "Secteur(s) *",
          rules: "required",
          type: "secteurs",
        },
        {
          value: [],
          placeholder: "Statut collecte",
          rules: "required",
          type: "statut",
        },
        {
          value: [],
          name: "date",
          placeholder: "Du XX/XX/XXXX au XX/XX/XXXX *",
          rules: "required",
          type: "date",
        },
        // {
        //   value: "",
        //   placeholder: "Plage horaire",
        //   rules: "required",
        //   type: "timestamp",
        //   export: "tournee",
        // },
      ],

      btn: [{ label: "Exporter", method: "export", type: "btn" }],

      marches: [
        {
          id_marche: 1,
          marche: "CCVE",
          prix_collecte_particulier: 33,
          prix_collecte_collectif: 283,
          tag: "CNCVE",
          prix_collecte_cancel: 15,
          createdAt: null,
          updatedAt: null,
        },
      ],
      secteurs: [],
      creneaux: [],
      horaires: [],
      types: [],
    };
  },

  methods: {
    getAllMarches() {
      apiMarches.getAllMarches().then((res) => {
        this.marches = res.data.marches;
      });
    },

    getTypesCollecte() {
      apiCollectes.getType().then((res) => {
        this.types = res.data.type;
      });
    },

    getSecteurs(id_marche) {
      console.log('getsecteur')
      apiMarches.getSecteurByMarche(id_marche).then((res) => {
        this.secteurs = res.data.secteurs;
      });
    },

    getPlageHoraire(id_secteur) {
      apiSecteurs.getHoraires(id_secteur).then((res) => {
        console.log("Les horaires: ", res.data);
        this.horaires = res.data.horaires;
      });
    },
  },

  created() {
    this.getTypesCollecte();
  },
};
</script>

<style scoped>
@import "export.css";
</style>
